import { axios } from "@db_ui/axios";
import { getEnvironment } from "utils/environment";
import extractModuleEnvKeyName from "./functions/extractModuleEnvKeyName";

const getTempStorageAccountEnvMap = (envs) => {
  const hostname = window.location.hostname;
  return {
    "local": `http://${hostname}:`,
    "dev": envs.MODULE_DOMAIN_DEV,
    "qa": envs.MODULE_DOMAIN_QA,
    "stg": envs.MODULE_DOMAIN_STG,
    "prod": envs.MODULE_DOMAIN_PROD
  }
}

const getModulePath = async ({ key, moduleDomain, devModuleDomain, pathPrefix, envs, environment }) => {
  const moduleEnvKeyName = extractModuleEnvKeyName(key) // ex: PROGRAM_MANAGEMENT from MODULE_NAME_PROGRAM_MANAGEMENT
  if (environment === "local") {
    const modulePort = envs[`PORT_${moduleEnvKeyName}`];
    let moduleLocation = `${moduleDomain}${modulePort}`;

    // TODO: Fix .map() not working when running modules locally and using this env
    if (envs["USE_REMOTE_MODULES"] === "true") {
      try {
        // TODO: Add this back when the .map() issue is fixed
        // await axios.get(`${moduleLocation}/moduleEntry.js`, { responseType: 'blob' });
        moduleLocation = `${devModuleDomain}${pathPrefix}${envs[key]}`; // TODO: remove this later
      }
      catch(err) {
        moduleLocation = `${devModuleDomain}${pathPrefix}${envs[key]}`;
      }
    }
    window[`MODULE_PATH_${moduleEnvKeyName}`] = moduleLocation;
  }
  else {
    window[`MODULE_PATH_${moduleEnvKeyName}`] = `${moduleDomain}${pathPrefix}${envs[key]}`;
  }
}

const attachEnvsToWindowObj = async (envs) => {
  window.db.env = envs;
  const pathPrefix = envs.MODULE_PATH_PREFIX;
  const environment = getEnvironment();
  const moduleDomain = getTempStorageAccountEnvMap(envs)[environment];
  const devModuleDomain = getTempStorageAccountEnvMap(envs)["dev"];
  const moduleNameKeys = Object.keys(envs).filter((key) => key.startsWith("MODULE_NAME"));

  const modulePathPromises = moduleNameKeys.map((key) => getModulePath({ key, moduleDomain, devModuleDomain, pathPrefix, envs, environment }));
  await Promise.allSettled(modulePathPromises);
};
export default attachEnvsToWindowObj;
