import attachEnvsToWindowObj from "./utils/attachEnvsToWindowObj";

window.db = {
  store: {},
  config: {}
};

await attachEnvsToWindowObj(process.env);

import("./bootstrap");
